
import {defineComponent, PropType} from "vue";

export default defineComponent({
  name: "VerticalBullet",
  props: {
    text: {
      type: Object as PropType<string>,
      required: true
    },
    color: {
      type: Object as PropType<string>,
      required: true
    }
  }
})
