
import {defineComponent, PropType, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import Button from "@/components/UI/Button.vue";
import InputGroup from "@/components/UI/InputGroup.vue";
import Selector2 from "@/components/inputs/SSelect2.vue";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {MembershipRole} from "@/models/enums/Role";
import {PeopleService} from "@/services/PeopleService";
import {Person} from "@/models/people/Person";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import Multiselect from "@vueform/multiselect";
import {required} from "@vuelidate/validators";

export default defineComponent({
  setup(_, {emit}) {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let person = ref<Person>({
      fullName: '',
      status: MembershipStatus.PendingEntity,
      title: '',
      role: MembershipRole.Member,
      avatar: ''
    } as Person);
    const toggle = (_person: Person) => {
      person.value = _person
      modal.value?.toggle()
    }
    const emitUpdated = () => {
      emit('updated')
      modal.value?.toggle();
    }
    return {modal, toggle, person, emitUpdated, v$: useVuelidate()}
  },
  components: {Selector2, InputGroup, Button, ModalBase, Multiselect},
  emits: ['updated'],
  data() {
    return {
      roleOptions: [
        {
          text: 'Member',
          id: 3
        },
        {
          text: 'Manager',
          id: 2
        },
        {
          text: 'Administrator',
          id: 1
        }
      ],
      isLoading: false,
      groupMemberships: [] as Array<string>
    }
  },
  validations() {
    return {
      person: {
        title: {required, mustHaveTitle}
      }
    }
  },
  props: {
    groups: {
      required: true,
      type: Object as PropType<Array<GroupViewModel>>
    }
  },
  methods: {
    submit(requestAccepted: boolean) {
      if (requestAccepted) {
        this.v$.person.$touch()
      }
      if ((requestAccepted && !this.v$.person.$invalid) || (!requestAccepted)) {
        this.isLoading = true;
        PeopleService.responseToRequest(
            {
              requestAccepted: requestAccepted,
              accountId: this.person.accountId,
              organizationId: this.organizationId,
              productionId: this.productionId,
              title: this.person.title,
              role: this.person.role,
              groups: this.groupMemberships
            }).then(() => {
          this.isLoading = false;
          if (requestAccepted) {
            Swal.fire(`${this.person.fullName} is now a member of this ${this.isOrganization ? 'organization' : 'production'}`)
          } else {
            Swal.fire(`The request from ${this.person.fullName} has been removed. They can no longer request to join this ${this.isOrganization ? 'organization' : 'production'}`)
          }
          this.emitUpdated();
        }).catch(() => {
          this.isLoading = false;
          Swal.fire('Oops! Looks like something went wrong.')
          this.emitUpdated();
        })
      }
    },
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      if (this.isOrganization) {
        return '00000000-0000-0000-0000-000000000000'
      }
      return this.$route.params['productionId'] as string
    },
    /**
     * We don't want to show the system-generated "Everyone" Group
     */
    groupsFiltered(): Array<GroupViewModel> {
      return this.groups.filter(x => !x.isEveryone)
    },
    groupsOptions(): Array<Object> {
      return this.groupsFiltered.map(group => {
        return {label: group.name, value: group.groupId}
      })
    },
  }
})
const mustHaveTitle = (value) => value !== 'To be assigned'
