
import {defineComponent, PropType, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import {PersonDetailed} from "@/models/people/PersonDetailed";
import {GenderIdentity, getGenderIdentity} from "@/models/enums/GenderIdentity";
import { PeopleService } from "@/services/PeopleService";
import { useRoute } from "vue-router";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  name: "ViewPersonModal",
  components: {ModalBase},
  setup() {
    const route = useRoute()
    const isOrganization = () => {
      return typeof route.name === "string" ? route.name.indexOf('organization') > -1 : false
    }
    const organizationId = () => {
      return route.params['organizationId'] as string
    }
    const productionId = () => {
      return route.params['productionId'] as string
    }
    const modal = ref<InstanceType<typeof ModalBase>>();
    let person = ref<PersonDetailed>({
      accountId: '',
      fullName: '',
      title: '',
      avatar: '',
      email: '',
      emergencyContacts: [],
      genderIdentity: GenderIdentity.Other,
      medical: '',
      stageCode: '',
      pronouns: '',
      emailAddresses: [],
      phoneNumbers: []
    } as PersonDetailed);
    const invoke = (accountId: string) => {
      if(isOrganization()){
        PeopleService.fetchOrganizationPerson(organizationId(), accountId).then(response => {
          person.value = response.data
          modal.value?.toggle()
        });
      } else {
        PeopleService.fetchProductionPerson(productionId(), accountId).then(response => {
          person.value = response.data
          modal.value?.toggle()
        });
      }
      WebAnalytics.trackFlexible('Viewed Person Detailed', {})
    }
    return {modal, person, invoke}
  },
  props: {
    groups: {
      required: true,
      type: Object as PropType<Array<GroupViewModel>>
    }
  },
  methods: {
    formatPhone(phone: string){
      return phone.replace(/[^0-9]/g, '')
          .replace(/(\d)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
    },
    getPersonsGroups(accountId: string) {
      return this.groupsFiltered.filter(group => group.members.filter(m => m === accountId).length > 0)
    },
  },
  computed: {
    phoneNumber(): string {
      const primary = this.person.phoneNumbers.filter(p => p.isPrimary)
      return primary.length > 0 ? this.formatPhone(primary[0].number) : 'No Phone Number';
    },
    emailAddress(): string {
      const primary = this.person.emailAddresses.filter(p => p.isPrimary)
      return primary.length > 0 ? primary[0].email : 'Unknown';
    },
    genderIdentity(): string {
      return getGenderIdentity(this.person.genderIdentity).name
    },
    /**
     * We don't want to show the system-generated "Everyone" Group
     */
    groupsFiltered(): Array<GroupViewModel> {
      return this.groups.filter(x => !x.isEveryone)
    },
  }
})
