
import {defineComponent, ref} from "vue";
import {Person} from "@/models/people/Person";
import {getMembershipRole, MembershipRole} from "@/models/enums/Role";
import {getMembershipStatus, MembershipStatus} from "@/models/enums/MembershipStatus";
import Button from "@/components/UI/Button.vue";
import ManagePersonModal from "@/components/modals/ManagePersonModal.vue";
import GroupModal from "@/components/modals/GroupModal.vue";
import AddPeopleModal from "@/components/modals/AddPeopleModal.vue";
import EntityRequestModal from "@/components/modals/EntityRequestModal.vue";
import ViewPersonModal from "@/components/modals/ViewPersonModal.vue";
import {PeopleService} from "@/services/PeopleService";
import {RemovePersonCommand} from "@/models/people/RemovePersonCommand";
import Swal from "sweetalert2";
import {GroupService} from "@/services/GroupService";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import {bus} from "@/modules/eventBus";
import moment from "moment";
import {useApplication} from "@/store/pinia/applicationStore";
import {useUser} from "@/store/pinia/userStore";
import {useToast} from "vue-toastification";
import {ProblemDetails} from "@/models/ProblemDetails";
import MeasurementsTable from "@/components/Entity/People/MeasurementsTable.vue";
import {ExportProductionMeasurementsRequest} from "@/models/productions/ExportProductionMeasurementsRequest";
import {ProductionService} from "@/services/ProductionService";
import VerticalBullet from "@/components/UI/VerticalBullet.vue";
import {useQuery} from "@tanstack/vue-query";
import {useRoute} from "vue-router";

export default defineComponent({
  name: "PeopleTab",
  setup() {
    const route = useRoute();
    const groupModal = ref<InstanceType<typeof GroupModal>>();
    const managePersonModal = ref<InstanceType<typeof ManagePersonModal>>();
    const viewPersonModal = ref<InstanceType<typeof ViewPersonModal>>();
    const addPeopleModal = ref<InstanceType<typeof AddPeopleModal>>();
    const entityRequestModal = ref<InstanceType<typeof EntityRequestModal>>();
    //Invoke Add Group
    const invokeAddGroup = () => {
      groupModal.value?.toggleAdd();
    }
    //Invoke Manage Group
    const invokeManageGroup = (group: GroupViewModel) => {
      groupModal.value?.toggleUpdate(group);
    }
    //Invoke Add People
    const invokeAddPeople = () => {
      addPeopleModal.value?.toggle();
    }
    //Invoke View Person
    const invokeViewPerson = (_accountId: string) => {
      viewPersonModal.value?.invoke(_accountId);
    }
    //Invoke Manage Person
    const invokeManagePerson = (person: Person) => {
      managePersonModal.value?.toggleUpdate(person);
    }
    //Invoke Respond to Request
    const invokeRequestResponse = (person: Person) => {
      entityRequestModal.value?.toggle(person);
    }
    const userStore = useUser();
    const applicationStore = useApplication();
    const toast = useToast();

    const productionId = () => {
      return route.params['productionId'] as string
    }
    const organizationId = () => {
      return route.params['organizationId'] as string
    }
    const isOrganization = () => {
      return typeof route.name === "string" ? route.name.indexOf('organization') > -1 : false
    }

    // Groups
    const groupQuery = useQuery({
      queryKey: [`groups`, isOrganization() ? organizationId() : productionId()],
      queryFn: ({queryKey}) => {
        if (isOrganization()) {
          return GroupService.fetchOrganizationQuery(queryKey[1] as string)
        } else {
          return GroupService.fetchProductionQuery(queryKey[1] as string)
        }
      },
    })

    // People
    const peopleQuery = useQuery({
      queryKey: [`people`, isOrganization() ? organizationId() : productionId()],
      queryFn: ({queryKey}) => {
        if (isOrganization()) {
          return PeopleService.fetchOrganizationQuery(queryKey[1] as string)
        } else {
          return PeopleService.fetchProductionQuery(queryKey[1] as string)
        }
      },
    })

    return {
      groupModal,
      managePersonModal,
      invokeAddGroup,
      invokeManageGroup,
      invokeAddPeople,
      invokeManagePerson,
      addPeopleModal,
      entityRequestModal,
      invokeRequestResponse,
      viewPersonModal,
      invokeViewPerson,
      userStore,
      applicationStore,
      toast,
      groupQuery,
      productionId,
      organizationId,
      isOrganization,
      peopleQuery
    }
  },
  components: {
    VerticalBullet,
    MeasurementsTable,
    ManagePersonModal, AddPeopleModal, GroupModal, Button, EntityRequestModal, ViewPersonModal
  },
  data() {
    return {
      exportOptions: [
        {
          text: 'PDF',
          id: 0
        }
      ],
      selectedExportOption: null,
      activeTab: 'grid',
      activeRequestsTab: 'requests',
      // people: [] as Array<Person>,
      // groups: [] as Array<GroupViewModel>,
      //peopleLoading: true,
      //groupsLoading: true,
      exportLoading: false
    }
  },
  mounted() {
    // this.fetch()
    this.activeTab = this.userStore.canManage(this.userStore.currentEntity) ? 'list' : 'grid'
    bus.on('modal:groups:added', () => {
      this.groupQuery.refetch()
    })
    bus.on('modal:groups:updated', () => {
      this.groupQuery.refetch()
    })
    /*if(this.$route.query){
      const query = this.$route.query as unknown as string
      if(query === 'incoming'){
        this.activeRequestsTab = 'requests'
      }
    }*/
  },
  methods: {
    fetch(){
      this.peopleQuery.refetch()
      this.groupQuery.refetch()
    },
    _fetch() {
      this.peopleLoading = true
      //this.groupsLoading = true
      if (this.isOrganization()) {
        PeopleService.fetchOrganization(this.organizationId()).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.peopleLoading = false
        })
        if (this.userStore.canManage(this.userStore.currentEntity)) {
          GroupService.fetchOrganization(this.organizationId()).then(response => {
            this.groups = response.data
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          }).finally(() => {
            //this.groupsLoading = false
          })
        }
      } else {
        PeopleService.fetchProduction(this.productionId()).then(response => {
          this.people = response.data
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.peopleLoading = false
        })
        if (this.userStore.canManage(this.userStore.currentEntity)) {
          GroupService.fetchProduction(this.productionId()).then(response => {
            this.groups = response.data
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          }).finally(() => {
            //this.groupsLoading = false
          })
        }
      }
    },
    removePerson(person: Person) {
      Swal.fire({
        title: `Are you sure you want to revoke access for ${person.fullName}?`,
        confirmButtonText: 'Revoke',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancel'
      }).then(response => {
        if (response.isConfirmed) {
          this.peopleLoading = true
          PeopleService.remove({
            accountId: person.accountId,
            organizationId: this.organizationId(),
            productionId: this.productionId()
          } as RemovePersonCommand).then(() => {
            Swal.fire(`Access for ${person.fullName} has been revoked.`)
            this.fetch()
          }).catch(err => {
            let errorDetails = err.response.data as ProblemDetails
            this.toast.error(errorDetails.detail)
          }).finally(() => {
            this.peopleLoading = false
          })
        }
      })
    },
    getGroupMember(accountId: string) {
      this.$log.info('getGroupMember', accountId);
      return this.people.find(person => person.accountId === accountId);
    },
    getRole(role: MembershipRole) {
      return getMembershipRole(role);
    },
    getStatus(status: MembershipStatus) {
      return getMembershipStatus(status);
    },
    getPersonsGroups(accountId: string) {
      return this.groupsFiltered.filter(group => group.members.filter(m => m === accountId).length > 0)
    },
    requestExport(groupIds: Array<string>) {
      this.toast.info('Generating Export. We\'ll let you know when your PDF is ready.')
      if (this.isOrganization()) {
        //console.log('requested export type', this.selectedExportOption);
        PeopleService.exportOrganizationContacts(this.organizationId(), 'pdf', groupIds).then(response => {
          //console.log('request complete', response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'ContactSheet.pdf');
          document.body.appendChild(link);
          link.click();
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        })
      } else {
        //console.log('requested export type', this.selectedExportOption);
        PeopleService.exportProductionContacts(this.productionId(), 'pdf', groupIds).then(response => {
          //console.log('request complete', response.data);
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'ContactSheet.pdf');
          document.body.appendChild(link);
          link.click();
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        })
      }
    },
    requestMeasurementExport(groupIds: Array<string>) {
      this.toast.info('Generating Export. We\'ll let you know when your PDF is ready.')
      //console.log('requested export type', this.selectedExportOption);
      ProductionService.exportProductionMeasurements({
        format: 'pdf',
        productionId: this.productionId(),
        groups: groupIds
      } as ExportProductionMeasurementsRequest).then(response => {
        //console.log('request complete', response.data);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'Measurements.pdf');
        document.body.appendChild(link);
        link.click();
      }).catch(err => {
        let errorDetails = err.response.data as ProblemDetails
        this.toast.error(errorDetails.detail)
      })
    },
    resendInvite(person: Person) {
      this.peopleLoading = true
      if (person.pronouns === 'EXTERNAL') {
        //This is an external invitation
        PeopleService.resendInvitationEmail({
          externalInvitationId: person.accountId,
          organizationId: this.organizationId(),
          productionId: this.productionId()
        }).then(() => {
          this.toast.success('Invitation email has been resent.')
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.peopleLoading = false
        })
      } else {
        PeopleService.resendInvitationEmail({
          accountId: person.accountId,
          organizationId: this.organizationId(),
          productionId: this.productionId()
        }).then(() => {
          this.toast.success('Invitation email has been resent.')
        }).catch(err => {
          let errorDetails = err.response.data as ProblemDetails
          this.toast.error(errorDetails.detail)
        }).finally(() => {
          this.peopleLoading = false
        })
      }
    },
    rescindInvite(person: Person) {
      Swal.fire({
        title: `Are you sure you want to rescind this invite?`,
        confirmButtonText: 'Rescind',
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: 'Cancel'
      }).then(response => {
        if (response.isConfirmed) {
          this.peopleLoading = true
          if (person.pronouns === 'EXTERNAL') {
            //This is an external invitation
            PeopleService.rescindInvitation({
              externalInvitationId: person.accountId,
              organizationId: this.organizationId(),
              productionId: this.productionId()
            }).then(() => {
              Swal.fire('Invitation has been rescinded.')
              this.fetch()
            }).catch(err => {
              let errorDetails = err.response.data as ProblemDetails
              this.toast.error(errorDetails.detail)
            })
          } else {
            PeopleService.rescindInvitation({
              accountId: person.accountId,
              organizationId: this.organizationId(),
              productionId: this.productionId()
            }).then(() => {
              Swal.fire('Invitation has been rescinded.')
              this.fetch()
            }).catch(err => {
              let errorDetails = err.response.data as ProblemDetails
              this.toast.error(errorDetails.detail)
            })
          }
        }
      })
    },
    formatDate(date: Date) {
      return moment(date).format('MM/DD/YYYY')
    },
    setTooltips() {
      setTimeout(function () {
        window.KTApp.initBootstrapTooltips()
      }, 500)
    }
  },
  computed: {
    people(): Array<Person> {
      if (this.peopleQuery.data.value) {
        return this.peopleQuery.data.value
      } else return []
    },
    activePeople(): Array<Person> {
      return this.people.filter(person => person.status === MembershipStatus.Active)
    },
    pendingRequests(): Array<Person> {
      return this.people.filter(person => person.status === MembershipStatus.PendingEntity)
    },
    pendingInvitations(): Array<Person> {
      return this.people.filter(person => person.status === MembershipStatus.PendingUser)
    },
    peopleLoading(): boolean {
      return this.peopleQuery.isLoading.value
    },
    /**
     * We don't want to show the system-generated "Everyone" Group
     */
    groups(): Array<GroupViewModel>{
      if (this.groupQuery.data.value) {
        return this.groupQuery.data.value
      } else return []
    },
    groupsFiltered(): Array<GroupViewModel> {
      if (this.groupQuery.data.value) {
        return this.groupQuery.data.value.filter(x => !x.isEveryone)
      } else return []
    },
    groupsLoading(): boolean {
      return this.groupQuery.isLoading.value
    }
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.fetch()
          }
        }
    )
  },
})
