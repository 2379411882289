
import {defineComponent, PropType, reactive, ref} from "vue";
import ModalBase from "@/components/ModalBase.vue";
import {Person} from "@/models/people/Person";
import {MembershipStatus} from "@/models/enums/MembershipStatus";
import {MembershipRole} from "@/models/enums/Role";
import InputGroup from "@/components/UI/InputGroup.vue";
import Selector2 from "@/components/inputs/SSelect2.vue";
import Button from "@/components/UI/Button.vue";
import {PeopleService} from "@/services/PeopleService";
import {UpdatePersonCommand} from "@/models/people/UpdatePersonCommand";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";
import {GroupViewModel} from "@/models/groups/GroupViewModel";
import {UpdatePersonGroupsCommand} from "@/models/people/ManageGroupPeopleCommand";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  setup(props, {emit}) {
    const modal = ref<InstanceType<typeof ModalBase>>();
    let person = reactive<Person>({
      fullName: '',
      accountId: '',
      status: MembershipStatus.Active,
      avatar: '',
      role: MembershipRole.Member,
      pronouns: '',
      title: ''
    } as Person);
    let mode = 'add';

    let groupMemberships = ref<Array<string>>([] as Array<string>);
    const setPersonsGroups = () => {
      groupMemberships.value = []
      const theirGroups = props.groups.filter(x => !x.isEveryone).filter(group => group.members.filter(m => m === person.accountId).length > 0)
      theirGroups.forEach(value => {
        groupMemberships.value.push(value.groupId)
      })
    }

    const toggleUpdate = (_person: Person) => {
      Object.assign(person, _person)
      mode = 'update';
      setPersonsGroups()
      modal.value?.toggle()
    }
    const emitUpdated = () => {
      emit('updated')
      modal.value?.toggle();
    }
    return {modal, toggleUpdate, mode, person, emitUpdated, v$: useVuelidate(), groupMemberships}
  },
  components: {Button, Selector2, InputGroup, ModalBase, Multiselect},
  emits: ['updated'],
  data() {
    return {
      roleOptions: [
        {
          text: 'Member',
          id: 3
        },
        {
          text: 'Manager',
          id: 2
        },
        {
          text: 'Administrator',
          id: 1
        }
      ],
      isLoading: false
    }
  },
  validations() {
    return {
      person: {
        title: {required}
      }
    }
  },
  props: {
    groups: {
      required: true,
      type: Object as PropType<Array<GroupViewModel>>
    }
  },
  methods: {
    update() {
      this.v$.person.$touch()
      if (!this.v$.person.$invalid) {
        this.isLoading = true;
        PeopleService.update({
          accountId: this.person.accountId,
          organizationId: this.organizationId,
          productionId: this.productionId,
          role: this.person.role,
          title: this.person.title
        } as UpdatePersonCommand).then(() => {
          this.updateGroups()
        }).catch(() => {
          Swal.fire('Oops! Sorry, something went wrong.')
          this.emitUpdated()
          this.isLoading = false;
        })
      }
    },
    updateGroups() {
      if (!this.isOrganization) {
        PeopleService.updateGroups({
          groups: this.groupMemberships,
          accountId: this.person.accountId,
          productionId: this.productionId
        } as UpdatePersonGroupsCommand).then(() => {
          this.isLoading = false;
          this.emitUpdated()
        })
      } else {
        this.isLoading = false;
        this.emitUpdated()
      }
    }
  },
  computed: {
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      if (this.isOrganization) {
        return '00000000-0000-0000-0000-000000000000'
      }
      return this.$route.params['productionId'] as string
    },
    /**
     * We don't want to show the system-generated "Everyone" Group
     */
    groupsFiltered(): Array<GroupViewModel> {
      return this.groups.filter(x => !x.isEveryone)
    },
    groupsOptions(): Array<Object> {
      return this.groupsFiltered.map(group => {
        return {label: group.name, value: group.groupId}
      })
    },
  }
})
