
import {defineComponent, PropType} from "vue";
import {ProductionService} from "@/services/ProductionService";
import {UserMeasurement} from "@/models/user/UserMeasurement";
import {useApplication} from "@/store/pinia/applicationStore";
import {Person} from "@/models/people/Person";
import VerticalBullet from "@/components/UI/VerticalBullet.vue";
import {MeasurementUnit} from "@/models/enums/MeasurementUnit";

export default defineComponent({
  components: {VerticalBullet},
  setup() {
    return {applicationStore: useApplication()}
  },
  data() {
    return {
      isLoading: false,
      measurements: [] as Array<UserMeasurement>
    }
  },
  props: {
    people: {
      required: true,
      type: Object as PropType<Array<Person>>
    }
  },
  mounted() {
    this.fetch()
  },
  methods: {
    fetch() {
      this.isLoading = true
      if (typeof this.productionId !== 'undefined' && this.productionId !== '00000000-0000-0000-0000-000000000000') {
        ProductionService.listProductionMeasurements(this.productionId).then(response => {
          this.measurements = response.data
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    getPerson(accountId): any {
      return this.people.find(x => x.accountId == accountId)
    },
    getUnit(measurement: UserMeasurement) {
      return measurement.unitSystem === MeasurementUnit.Imperial ? 'in' : 'cm'
    }
  },
  computed: {
    availableMeasurements(): Array<UserMeasurement> {
      return this.measurements.filter(x => x !== null)
    },
    isOrganization(): boolean {
      return typeof this.$route.name === "string" ? this.$route.name.indexOf('organization') > -1 : false
    },
    organizationId(): string {
      return this.$route.params['organizationId'] as string
    },
    productionId(): string {
      if (this.isOrganization) {
        return '00000000-0000-0000-0000-000000000000'
      }
      return this.$route.params['productionId'] as string
    },
  },
  created() {
    this.$watch(
        () => this.$route.params,
        () => {
          if (this.applicationStore.isEntityMode) {
            this.fetch()
          }
        }
    )
  },
})
