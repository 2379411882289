import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center mb-8 mt-10" }
const _hoisted_2 = { class: "mb-3" }
const _hoisted_3 = { class: "d-lg-flex flex-row flex-equal" }
const _hoisted_4 = { class: "d-flex justify-content-center mb-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_Selector2 = _resolveComponent("Selector2")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ModalBase = _resolveComponent("ModalBase")!

  return (_openBlock(), _createBlock(_component_ModalBase, {
    title: "",
    size: "modal-md",
    ref: "modal",
    "has-toolbar": true,
    "is-loading": _ctx.isLoading
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, "Accept Request from " + _toDisplayString(_ctx.person.fullName), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_InputGroup, {
          "is-required": true,
          label: "Title",
          class: "me-4"
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("input", {
              class: _normalizeClass(["form-control", {'is-invalid': _ctx.v$.person.title.$error}]),
              type: "text",
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.person.title) = $event))
            }, null, 2), [
              [_vModelText, _ctx.person.title]
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_InputGroup, {
          "is-required": true,
          label: "Role",
          class: "me-4"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Selector2, {
              class: "",
              options: _ctx.roleOptions,
              required: true,
              modelValue: _ctx.person.role,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.person.role) = $event))
            }, null, 8, ["options", "modelValue"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", null, [
        _createVNode(_component_InputGroup, {
          label: "Groups",
          "is-required": false,
          tooltip: "Add this user to some groups now and they will see all communications and calls scheduled for those groups."
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Multiselect, {
              modelValue: _ctx.groupMemberships,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.groupMemberships) = $event)),
              options: _ctx.groupsOptions,
              mode: "tags",
              searchable: true,
              trackBy: "label"
            }, null, 8, ["modelValue", "options"])
          ]),
          _: 1
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Button, {
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.submit(false))),
          class: "btn-light-danger text-white me-6",
          type: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("Decline Request ")
          ]),
          _: 1
        }),
        _createVNode(_component_Button, {
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.submit(true))),
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Accept & Save")
          ]),
          _: 1
        })
      ])
    ]),
    _: 1
  }, 8, ["is-loading"]))
}