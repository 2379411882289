import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center mb-6" }
const _hoisted_2 = { class: "me-5 position-relative" }
const _hoisted_3 = { class: "symbol symbol-50px symbol-circle" }
const _hoisted_4 = {
  key: 0,
  class: "symbol-label bg-light-danger text-danger fw-bold"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "d-flex flex-column justify-content-center" }
const _hoisted_7 = { class: "mb-1 fs-5 text-gray-800 text-hover-primary" }
const _hoisted_8 = { class: "fw-bold fs-6 text-gray-600" }
const _hoisted_9 = { class: "d-flex justify-content-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputGroup = _resolveComponent("InputGroup")!
  const _component_Selector2 = _resolveComponent("Selector2")!
  const _component_Multiselect = _resolveComponent("Multiselect")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ModalBase = _resolveComponent("ModalBase")!

  return (_openBlock(), _createBlock(_component_ModalBase, {
    title: `Manage ${_ctx.person.fullName}`,
    size: "modal-md",
    ref: "modal",
    "is-loading": _ctx.isLoading
  }, {
    body: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.person.avatar)
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.person.fullName[0]), 1))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  alt: "Pic",
                  src: _ctx.person.avatar
                }, null, 8, _hoisted_5))
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.person.fullName), 1),
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.person.pronouns), 1)
        ])
      ]),
      _createVNode(_component_InputGroup, {
        "is-required": true,
        label: "Title/Character"
      }, {
        default: _withCtx(() => [
          _withDirectives(_createElementVNode("input", {
            class: _normalizeClass(["form-control", {'is-invalid': _ctx.v$.person.title.$error}]),
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.person.title) = $event))
          }, null, 2), [
            [_vModelText, _ctx.person.title]
          ])
        ]),
        _: 1
      }),
      (_ctx.person.role !== 0)
        ? (_openBlock(), _createBlock(_component_InputGroup, {
            key: 0,
            "is-required": true,
            label: "Role"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Selector2, {
                class: "",
                options: _ctx.roleOptions,
                required: true,
                modelValue: _ctx.person.role,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.person.role) = $event)),
                modelModifiers: { number: true }
              }, null, 8, ["options", "modelValue"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.isOrganization)
        ? (_openBlock(), _createBlock(_component_InputGroup, {
            key: 1,
            label: "Groups",
            "is-required": false
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Multiselect, {
                modelValue: _ctx.groupMemberships,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.groupMemberships) = $event)),
                options: _ctx.groupsOptions,
                mode: "tags",
                searchable: true,
                trackBy: "label"
              }, null, 8, ["modelValue", "options"])
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_Button, {
          onClick: _ctx.update,
          type: "primary"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Save Changes")
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }, 8, ["title", "is-loading"]))
}